import NominatifSavingAccountProductListPdf from "@/components/page/nominatif-saving-account-product-list-pdf";

export default {
  name: "Product",
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      return getSession;
    },
  },
  components: {
    NominatifSavingAccountProductListPdf,
  },
  data() {
    return {
      property: {
        listElement: {
          nominatif: {
            currentPage: 1,
            rows: 0,
            perPage: 10,
            message: "",
            filteredDate: "",
            downloading: false,
          },
        },
        animation: {
          isDownloadingFile: false,
          submitLoading: false,
        },
      },

      form: {
        savingCode: "",
        officeCode: "",
        userCode: "",
        isComaDelimited: false,
      },

      options: {
        kodeProduk: [],
        office: [],
        accountOfficer: [],
      },
    };
  },
  methods: {
    async getReferenceSaving() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_TRANSACTION",
          {
            url: "saving/simple-list",
            params: {
              name: "",
              page: 0,
            },
          }
        );
        this.options.kodeProduk = [{ value: "", text: "-- Pilih --" }];
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.savingCode;
            const text = i.savingName;
            this.options.kodeProduk.push({
              text,
              value,
            });
          });
        }
      } catch (error) {}
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeCode;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    appendDefaultSavingAccount() {
      this.options.kodeProduk = [{ value: "", text: "-- Pilih --" }];
      this.options.accountOfficer = [{ value: "", text: "-- Pilih --" }];
    },
    setDefaultOfficeCodeValue() {
      const getUserDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.form.officeCode =
        getUserDataFromSession.officeCode ===
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_OFFICE_CENTRAL
          ? ""
          : getUserDataFromSession.officeCode;
    },
    async getReferenceUserAccountOfficer() {
      // try {
      //   const resp = await this.$store.dispatch(
      //     "GET_REFERENCE_FROM_USER_MANAGEMENT",
      //     {
      //       params: {
      //         page: 0,
      //       },
      //       url: "v2/user/active-user",
      //     }
      //   );
      //   if (resp) {
      //     resp.data.data.content.map((index) => {
      //       const text = `${index.profileSurename} - ${index.userNik}`;
      //       const value = index.userCode;
      //       this.options.accountOfficer.push({ text, value });
      //     });
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/reference",
          payload: {
            jobTitle: "",
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountOfficerName} - ${index.accountOfficerCode}`;
            const value = index.accountOfficerId;
            this.options.accountOfficer.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async onDownloadNominativeSavingUs(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        savingCode: this.form.savingCode,
        officeCode: this.form.officeCode,
        userCode: this.form.userCode,
        isComaDelimiter: true,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/saving-nominative-per-account",
          payload: payload,
        });
        console.log("test download template", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Nominatif_Tabungan_.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadNominativeSavingIdn(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        savingCode: this.form.savingCode,
        officeCode: this.form.officeCode,
        userCode: this.form.userCode,
        isComaDelimiter: false,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/saving-nominative-per-account",
          payload: payload,
        });
        console.log("test download template", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Nominatif_Tabungan_.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
  },
  mounted() {
    this.setDefaultOfficeCodeValue();
    this.getReferenceSaving();
    this.appendDefaultSavingAccount();
    this.getReferenceOffice();
    this.getReferenceUserAccountOfficer();
  },
};
